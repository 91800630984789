import * as React from 'react'
import { Helmet } from 'react-helmet'
import logo from '../images/3dmeetLogo.png'

import GoogleAuth from '../components/GoogleClassroom/GoogleAuth'
import ListCourses from '../components/GoogleClassroom/ListCourses'
import LoadableGAPIProvider from '../components/GoogleClassroom/LoadableGAPIProvider'
import '../styles/global.css'
import '../styles/courses.css'
import '../styles/teacher.page.css'

const PAGE_CLASSES = 'page-style'
const LOGOUT_BUTTON_CLASSES = 'btn right-float'
const AUTH_CONTAINER_CLASSES = 'left-text center course-width'
const LOGO_CLASSES = 'logo'
const LOGO_ALT = '3Dmeet.com'

const pageStyles = {
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
}

const TEACHER_SCOPES = [
  'https://www.googleapis.com/auth/classroom.courses.readonly',
  'https://www.googleapis.com/auth/classroom.rosters.readonly',
  'https://www.googleapis.com/auth/classroom.announcements',
]

const TeacherPage = (): JSX.Element => {
  return (
    <LoadableGAPIProvider clientId={process.env.GATSBY_GAPI_CLIENT_ID}>
      <main style={pageStyles} className={PAGE_CLASSES}>
        <Helmet>
          <title>Google Classroom Teachers</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0"
          />
        </Helmet>
        <img src={logo} className={LOGO_CLASSES} alt={LOGO_ALT} />
        <GoogleAuth
          requestedScopes={TEACHER_SCOPES}
          authContainerStyle={AUTH_CONTAINER_CLASSES}
          logoutButtonStyle={LOGOUT_BUTTON_CLASSES}
        />
        <ListCourses />
      </main>
    </LoadableGAPIProvider>
  )
}

export default TeacherPage

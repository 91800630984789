import React, { useCallback, useState } from 'react'
import { useEffect } from 'react'

import CourseItem from './CourseItem'
import { GoogleClass, GoogleClassState } from '../../types/GoogleClassroom'

const ANNOUNCMENT_SUCCESS = 'success'
const ANNOUNCEMENT_FAILED = 'failure'
const ANNOUNCEMENT_ERROR = 'error'

// HACK: This prevents the typescript error of `Property 'gapi' does not exist on type 'Window & typeof globalThis'` and we can't import it
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
declare const window: Window & typeof globalThis & { gapi: any }

const ListCourses = (): JSX.Element => {
  const [courses, setCourses] = useState<Array<GoogleClass>>([])
  const [gapi, setGAPI] = useState(undefined)

  const retrieveGAPI = useCallback(() => {
    if (typeof gapi === 'undefined' && typeof window !== 'undefined') {
      setGAPI(window.gapi)
      setTimeout(retrieveGAPI, 0)
    }
  }, [gapi, setGAPI])

  useEffect(() => {
    setTimeout(retrieveGAPI, 0)
  }, [])

  const retrieveCourses = useCallback(async () => {
    if (
      !gapi ||
      !gapi.client ||
      !gapi.client.classroom ||
      !gapi?.auth2?.getAuthInstance().isSignedIn.get()
    ) {
      setTimeout(retrieveCourses, 0)
      return
    }

    const courseResult = await gapi.client.classroom.courses.list({
      teacherId: 'me',
      courseStates: [GoogleClassState.Active],
    })
    setCourses(courseResult.result.courses)
  }, [gapi, setCourses])

  const sendAnnouncement = useCallback(
    async (
      courseId: string,
      attendUrl: string,
      announcement: string,
    ): Promise<string> => {
      try {
        // TODO: Notify user of status (success/failure)
        const result = await gapi.client.classroom.courses.announcements.create(
          {
            courseId: courseId,
            text: announcement,
            materials: [
              {
                link: {
                  url: attendUrl,
                },
              },
            ],
          },
        )
        if (result.status != 200) {
          return ANNOUNCEMENT_FAILED
        }
        return ANNOUNCMENT_SUCCESS
      } catch (err) {
        console.error('Could not send announcment', err)
        return ANNOUNCEMENT_ERROR
      }
    },
    [gapi],
  )

  useEffect(() => {
    retrieveCourses()
  }, [gapi])

  return !gapi ? (
    <div>Retrieving active courses</div>
  ) : (
    <div>
      {courses.map((course: GoogleClass): JSX.Element => {
        return (
          <CourseItem
            key={course.id}
            course={course}
            announcementHandler={sendAnnouncement}
          />
        )
      })}
    </div>
  )
}

export default ListCourses

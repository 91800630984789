import * as React from 'react'
import { useCallback } from 'react'
import { useState } from 'react'
import '../../styles/courses.css'
import '../../styles/buttons.css'

import { GoogleClass } from '../../types/GoogleClassroom'

const CODE_ANNOUNCMENT_SUCCESS = 'success'
const CONTAINER_CLASSES = 'course center'
const RIGHT_LINE_CLASSES = 'right-childern course-line'
const COURSE_LINK_CLASSES = 'course-link right-text'
const INPUT_BAR_CLASSES = 'input-bar'
const SEND_BUTTON_DEFAULT_MESSAGE = 'Send Announcement with Link'
const SEND_BUTTON_LOADING_MESSAGE = 'Sending Announcement'
const SEND_BUTTON_SUCCESS_MESSAGE = 'Sent Announcement Successfully'
const SEND_BUTTON_FAILED_MESSAGE = 'Failed to Send'
const ANNOUNCMENT_CLASSES_DEFAULT = 'btn'
const ANNOUNCMENT_CLASSES_SUCCESS = 'success-button'
const ANNOUNCEMENT_CLASSES_FAILURE = 'failed-button'
const WAIT_TIME = 3000

export type GoogleClassItemProp = {
  course: GoogleClass
  announcementHandler: (
    courseId: string,
    attendUrl: string,
    announcement: string,
  ) => Promise<string>
}

const GoogleClassItem = ({
  course,
  announcementHandler,
}: GoogleClassItemProp): JSX.Element => {
  const [announcementText, setAnnouncementText] = useState('Attend class')
  const [copyButtonText, setCopyButtonText] = useState('Copy')
  const attendUrl = `${process.env.GATSBY_SITE_BASE_URL}${
    process.env.GATSBY_SITE_GOOGLE_CLASSROOM_JOIN_PATH
  }?class=${encodeURIComponent(course.id)}`
  const [copyButtonClasses, setCopyButtonClasses] = useState('btn')
  const [cancelCopyTimer, setCancelCopyTimer] = useState(null)
  const [announcementClasses, setAnnouncementClasses] = useState(
    ANNOUNCMENT_CLASSES_DEFAULT,
  )
  const [cancelAnnouncementTimer, setAnnouncementTimer] = useState(null)
  const [announcementButtonText, setAnnouncementButtonText] = useState(
    SEND_BUTTON_DEFAULT_MESSAGE,
  )
  const [loadingAnnouncement, setLoadingAnnouncement] = useState(false)

  const handleAnnouncementChange = useCallback(
    (event) => {
      setAnnouncementText(event.target.value)
    },
    [setAnnouncementText],
  )

  const copyToClipBoard = useCallback(() => {
    navigator.clipboard.writeText(attendUrl)
    if (cancelCopyTimer && typeof cancelCopyTimer === 'function')
      cancelCopyTimer()
    setCopyButtonText('Copied')
    setCopyButtonClasses('success-button')
    const timer = setTimeout(() => {
      setCopyButtonText('Copy')
      setCopyButtonClasses('btn')
    }, WAIT_TIME)
    setCancelCopyTimer(() => () => {
      clearTimeout(timer)
    }) //Double anonymous function hack to add clear timeout functions to state
  }, [
    cancelCopyTimer,
    setCopyButtonClasses,
    setCopyButtonText,
    setCancelCopyTimer,
  ])

  const setUpAnnouncementTimer = useCallback(() => {
    if (
      cancelAnnouncementTimer &&
      typeof cancelAnnouncementTimer === 'function'
    )
      cancelAnnouncementTimer()

    const timer = setTimeout(() => {
      setAnnouncementButtonText(SEND_BUTTON_DEFAULT_MESSAGE)
      setAnnouncementClasses(ANNOUNCMENT_CLASSES_DEFAULT)
    }, WAIT_TIME)
    setAnnouncementTimer(() => () => clearTimeout(timer))
  }, [
    cancelAnnouncementTimer,
    setAnnouncementButtonText,
    setAnnouncementClasses,
    setAnnouncementTimer,
  ])

  const announcementButtonHandler = useCallback(async () => {
    if (!loadingAnnouncement) {
      setLoadingAnnouncement(true)
      setAnnouncementButtonText(SEND_BUTTON_LOADING_MESSAGE)
      const result = await announcementHandler(
        course.id,
        attendUrl,
        announcementText,
      )
      if (result === CODE_ANNOUNCMENT_SUCCESS) {
        setAnnouncementClasses(ANNOUNCMENT_CLASSES_SUCCESS)
        setAnnouncementButtonText(SEND_BUTTON_SUCCESS_MESSAGE)
      } else {
        setAnnouncementClasses(ANNOUNCEMENT_CLASSES_FAILURE)
        setAnnouncementButtonText(SEND_BUTTON_FAILED_MESSAGE)
      }
      setUpAnnouncementTimer()
      setLoadingAnnouncement(false)
    }
  }, [
    loadingAnnouncement,
    setLoadingAnnouncement,
    setAnnouncementButtonText,
    setAnnouncementClasses,
    announcementText,
  ])

  return (
    <div className={CONTAINER_CLASSES}>
      <h3>{`${course.name}`}</h3>
      {course.section ? <h4>Section: {`${course.section}`}</h4> : null}
      <br />
      <div className={RIGHT_LINE_CLASSES}>
        <p className={COURSE_LINK_CLASSES}>Meeting Link: {attendUrl}</p>
        <button onClick={copyToClipBoard} className={copyButtonClasses}>
          {copyButtonText}
        </button>
      </div>
      <div className={RIGHT_LINE_CLASSES}>
        <input
          type="text"
          name="announcementText"
          placeholder="Add message to announcement"
          value={announcementText}
          className={INPUT_BAR_CLASSES}
          onChange={handleAnnouncementChange}
        />
        <button
          onClick={announcementButtonHandler}
          className={announcementClasses}
        >
          {announcementButtonText}
        </button>
      </div>
    </div>
  )
}

export default GoogleClassItem
